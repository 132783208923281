import React from 'react'
import { useSession } from 'next-auth/react'
import { useRouter } from 'next/router'
import { Button, Icon } from '@chakra-ui/react'
import { FaMoneyBillAlt } from 'react-icons/fa'
import { RiHeartFill, RiCalendarFill } from 'react-icons/ri'
import { MdLoop } from 'react-icons/md'
import { BsShop } from 'react-icons/bs'
import { RefetchQueriesEnum, DataLimit } from 'src/constants'
import {
  OrdersFilterData,
  OrderStatusEnum,
  useBackofficeOrdersQuery,
  useNewOrderSubscription,
} from 'src/generated/graphql-frontend'
import { getPageFromOffset } from 'utils/helpers'
import { DataTable, EmptyTableContent, HeaderItem } from 'components/DataTable'
import { Pagination } from 'components/Pagination'
import { NewProductProps } from '../../types'
import { OrderRow } from './OrderRow'
import useTranslation from 'next-translate/useTranslation'

interface Props extends NewProductProps {
  filterData: Required<NonNullable<OrdersFilterData>>
  pageParamName?: string
  /**
   * Used to add query parameter when navigating to an order to provide a correct back link to where the user came from
   */
  isFromDashboard?: boolean
}

export const OrdersGrid: React.FC<Props> = ({
  filterData,
  pageParamName = 'page',
  isFromDashboard,
  onNewProduct,
}) => {
  const { t } = useTranslation('backOffice')
  const session = useSession()
  const router = useRouter()
  const page = router.query[pageParamName] as string | undefined
  const pagination = React.useMemo(
    () => ({
      from: getPageFromOffset({ limitPerPage: DataLimit.backofficeProducts, page }),
      length: DataLimit.backofficeProducts,
    }),
    [page]
  )
  const { data, loading } = useBackofficeOrdersQuery({
    variables: {
      filterData,
      pagination,
    },
    fetchPolicy: 'network-only',
  })
  const profileId = session.data?.user.profile?.id
  useNewOrderSubscription({
    variables: {
      profileId: profileId!,
      status: filterData.status!,
    },
    skip: !profileId,
    onSubscriptionData({ client }) {
      const pagination = data?.backofficeOrders.pagination
      if (pagination?.from === 0) {
        void client.refetchQueries({
          include: [RefetchQueriesEnum.BackofficeOrders],
        })
      }
    },
  })
  const isCarts = filterData?.status?.[0] === OrderStatusEnum.Draft
  const headerItems = React.useMemo<HeaderItem[]>(
    () => [
      {
        icon: RiHeartFill,
        label: {
          base: t('Shop.customerNameShort'),
          // eslint-disable-next-line quotes
          xl: t('Shop.customerName'),
        },
      },
      {
        icon: RiCalendarFill,
        label: {
          base: t('Shop.date'),
          xl: isCarts ? t('Shop.createdAt') : t('Shop.orderDate'),
        },
      },
      {
        icon: MdLoop,
        label: t('Shop.status'),
        showFrom: 'xl',
      },
      {
        icon: FaMoneyBillAlt,
        label: t('Shop.price'),
      },
    ],
    [isCarts, t]
  )
  return (
    <DataTable
      emptyComp={
        <EmptyTableContent
          ctaButton={
            <Button iconSpacing="3" leftIcon={<Icon as={BsShop} />} onClick={onNewProduct}>
              {t('Shop.createNewProduct')}
            </Button>
          }
          imageWidth={{ base: '59px', md: '147px' }}
          imageHeight={{ base: '56px', md: '140px' }}
          imageUrl="/images/empty-cart.svg"
          imageAlt="empty cart with plantico logo"
          // eslint-disable-next-line quotes
          title={isCarts ? t('Shop.noActiveShoppingCarts') : t('Shop.noOrdersYet')}
          description={t('Shop.noOrdersCTA')}
        />
      }
      headerItems={headerItems}
      itemKeyProp="id"
      renderTableRow={(item) => (
        <OrderRow
          {...item}
          currency={session.data!.user.profile!.currency}
          isFromDashboard={isFromDashboard}
        />
      )}
      templateColumns={{
        base: '3fr repeat(2, 2fr)',
        sm: '4fr repeat(2, 2.5fr)',
        xl: '2fr repeat(3, 1fr)',
      }}
      data={data?.backofficeOrders.orders}
      skeletonRowsCount={DataLimit.backofficeOrders}
      footer={
        !!data?.backofficeOrders.orders.length && (
          <Pagination
            lastPage={Math.ceil(
              data.backofficeOrders.pagination.total / DataLimit.backofficeOrders
            )}
            currentPage={Number.parseInt(page || '1')}
            onGeneratePageLink={(pageNumber) =>
              `${router.asPath.split('?')[0]}?${pageParamName}=${pageNumber}`
            }
          />
        )
      }
      isLoading={loading}
    />
  )
}
