import React from 'react'
import { Portal } from '@chakra-ui/react'
import {
  MyCartsQuery,
  useAddToOrderMutation,
  useMyCartsQuery,
  useProductAttributesLazyQuery,
} from 'src/generated/graphql-frontend'
import { RefetchQueriesEnum, TrackNamesEnum } from 'src/constants'
import { VariantDrawer } from './VariantDrawer'
import { AddProductInput, RequiredAddProductInput } from './types'
import { mixpanel } from 'utils/mixpanel'

interface ShopContextProps {
  myCarts: MyCartsQuery['myCarts']
  refetchMyCarts: ReturnType<typeof useMyCartsQuery>['refetch']
  addToCart(data: AddProductInput): Promise<boolean>
  shouldShowAddedToCartPopover: boolean
}

const variantWaitDefaultData = {
  resolve: (() => undefined) as (data: RequiredAddProductInput) => void,
  reject: (() => undefined) as (reason?: any) => void,
}

export const ShopContext = React.createContext<ShopContextProps>({
  myCarts: [],
  refetchMyCarts: (() => {}) as ShopContextProps['refetchMyCarts'],
  addToCart: () => Promise.resolve(false),
  shouldShowAddedToCartPopover: false
})

export const ShopContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const myCartsQuery = useMyCartsQuery()
  const [variantDrawerProductId, setVariantDrawerProductId] = React.useState<string>()
  const [isBuyNow, setIsBuyNow] = React.useState(false)
  const [shouldShowAddedToCartPopover, setShouldShowAddedToCartPopover] = React.useState(false)
  const variantPromiseRef = React.useRef(variantWaitDefaultData)
  const shouldShowAddedToCartPopoverTimeoutRef = React.useRef<null | number>(null)
  const handleVariantDrawerSubmit = React.useCallback((data: RequiredAddProductInput) => {
    variantPromiseRef.current.resolve(data)
    variantPromiseRef.current = variantWaitDefaultData
    setVariantDrawerProductId(undefined)
  }, [])
  const handleVariantDrawerClose = React.useCallback(() => {
    variantPromiseRef.current.reject()
    variantPromiseRef.current = variantWaitDefaultData
    setVariantDrawerProductId(undefined)
  }, [])
  const [fetchProductOptions, { data: productOptions, refetch: refetchProductOptions }] =
    useProductAttributesLazyQuery()
  const [addToCartMutation] = useAddToOrderMutation({
    refetchQueries: [RefetchQueriesEnum.MyCarts],
    awaitRefetchQueries: true,
    onCompleted() {
      setShouldShowAddedToCartPopover(true)
      shouldShowAddedToCartPopoverTimeoutRef.current = window.setTimeout(() => {
        setShouldShowAddedToCartPopover(false)
      }, 1500)
    },
  })
  const addVariantToCart = React.useCallback(
    async ({ productId, variantId, quantity, timeSlot }: RequiredAddProductInput) => {
      await addToCartMutation({
        variables: {
          inputData: {
            quantity,
            productId,
            variantId,
            timeSlot,
          },
        },
      })
      return true
    },
    []
  )
  const addToCart = React.useCallback(
    async ({ productId, variantId, hasOptions, quantity = 1, isBuyNow, timeSlot, amountInCent, currency, source }: AddProductInput) => {
      if (hasOptions && !variantId && productId) {
        try {
          if (productOptions?.product) {
            await refetchProductOptions({ productId })
          } else {
            await fetchProductOptions({ variables: { productId } })
          }
          setIsBuyNow(!!isBuyNow)
          setVariantDrawerProductId(productId)
          const productInput: RequiredAddProductInput = await new Promise((resolve, reject) => {
            variantPromiseRef.current = { resolve, reject }
          })
          const result = await addVariantToCart(productInput)
          mixpanel.track(TrackNamesEnum.AddToCart, {
            amountInCent,
            currency,
            source,
            productId,
            variantId,
            quantity,
            timeSlot,
          })
          return result
        } catch {
          return false
        }
      } else if (productId || variantId) {
        const result = await addVariantToCart({ productId, variantId, quantity, timeSlot })
        mixpanel.track(TrackNamesEnum.AddToCart, {
          amountInCent,
          currency,
          source,
          productId,
          variantId,
          quantity,
          timeSlot,
        })
        return result
      }
      return false
    },
    [addVariantToCart, productOptions]
  )

  React.useEffect(() => () => {
      if (shouldShowAddedToCartPopoverTimeoutRef.current !== null) {
        window.clearTimeout(shouldShowAddedToCartPopoverTimeoutRef.current)
      }
    }, [])

  return (
    <ShopContext.Provider
      value={{
        myCarts: myCartsQuery?.data?.myCarts || [],
        refetchMyCarts: myCartsQuery.refetch,
        addToCart,
        shouldShowAddedToCartPopover,
      }}
    >
      {children}
      <Portal>
        <VariantDrawer
          productId={variantDrawerProductId}
          options={productOptions?.product?.AttributeProduct}
          isBuyNow={isBuyNow}
          onSubmit={handleVariantDrawerSubmit}
          onClose={handleVariantDrawerClose}
        />
      </Portal>
    </ShopContext.Provider>
  )
}

export const useShopContext = () => {
  const context = React.useContext(ShopContext)
  return context
}

export const { Consumer } = ShopContext
