import React from 'react'
import useTranslation from 'next-translate/useTranslation'
import { Controller, useFormContext } from 'react-hook-form'
import { Button, Checkbox, Text } from '@chakra-ui/react'
import debounce from 'lodash/debounce'
import { Currencies, CurrencyType, getPrice } from 'utils/payments'
import { EmbedProps, WidgetColorType, WidgetEmbedForm } from 'components/Widget/constants'
import colors from 'utils/theme/foundations/colors'
import { mixpanel } from 'utils/mixpanel'
import { TrackNamesEnum } from 'src/constants'
import { PaymentTypeEnum } from 'src/generated/graphql-frontend'
import { AmountInput } from 'components/AmountInput'
import { Step, StepProps } from '..'
import { AmountPick } from './AmountPick'
import { usePaymentRequest } from 'utils/hooks'

const minAmount = 199

export const Amount: React.FC<Pick<EmbedProps, 'profile'> & StepProps> = ({
  profile,
  nextStep,
  onSubmit,
  ...props
}) => {
  const { t } = useTranslation('common')
  const { setAmount } = usePaymentRequest()
  const amountInputRef = React.useRef<HTMLInputElement>(null)
  const { handleSubmit, watch, getValues, setValue, control } = useFormContext<WidgetEmbedForm>()
  const debouncedTrack = React.useRef(debounce(mixpanel.track, 1000))
  React.useEffect(() => {
    const subscription = watch(({ amountCents }, { name, type }) => {
      if (amountCents === 0) {
        amountInputRef?.current?.focus()
      }
      if (name === 'amountCents') {
        setAmount(amountCents ?? null)
        debouncedTrack.current('payment amount change', {
          paymentStep: 2,
          profile,
          amountCents,
          type,
        })
      }
    })
    return subscription.unsubscribe
  }, [])
  const { currency, productSettings } = profile
  const { widgetColorScheme } = productSettings || {}
  return (
    <Step
      {...props}
      as="form"
      onSubmit={
        nextStep
          ? handleSubmit(
              () => {
                nextStep(true)
                mixpanel.track(TrackNamesEnum.PaymentButton, {
                  paymentStep: 2,
                  profile,
                  amountCents: getValues('amountCents'),
                })
              },
              () => amountInputRef.current?.focus()
            )
          : undefined
      }
    >
      <Controller
        control={control}
        name="amountCents"
        rules={{
          required: t('validDonationError'),
          min: minAmount,
        }}
        render={({ field: { ref, ...field }, fieldState: { error } }) => (
          <AmountInput
            ref={amountInputRef}
            width="full"
            alignSelf="stretch"
            textAlign="center"
            fontSize="xl"
            fontWeight="medium"
            borderRadius="2xl"
            variant="outline"
            borderColor={widgetColorScheme || undefined}
            color={widgetColorScheme || undefined}
            currencyName={Currencies[currency as CurrencyType].name}
            shouldFocusOnZero
            showZero={false}
            _focus={
              widgetColorScheme
                ? {
                    borderColor: colors[widgetColorScheme],
                    boxShadow: `0 0 0 1px ${
                      (colors[widgetColorScheme.split('.')[0]] as Record<string, string>)[
                        widgetColorScheme.split('.')[1]
                      ]
                    }`,
                  }
                : undefined
            }
            errorMessage={
              error?.message ||
              (error?.type === 'min' &&
                t('donationMinError', {
                  amount: getPrice(minAmount, currency),
                })) ||
              undefined
            }
            {...field}
          />
        )}
      />
      <Controller
        control={control}
        name="amountCents"
        render={({ field: { ref, ...field } }) => (
          <AmountPick
            currency={currency}
            colorScheme={productSettings?.widgetColorScheme}
            {...field}
          />
        )}
      />
      <Controller
        control={control}
        name="isRecurringDonation"
        render={({ field: { value, onChange } }) => (
          <Checkbox
            isChecked={value}
            onChange={(event) => {
              onChange(event)
              setValue(
                'paymentType',
                event.currentTarget.checked ? PaymentTypeEnum.Card : undefined
              )
            }}
            colorScheme={widgetColorScheme || undefined}
          >
            <Text fontSize="sm" color={widgetColorScheme || undefined}>
              {t('monthlyPayment')}
            </Text>
          </Checkbox>
        )}
      />
      <Button
        type="submit"
        alignSelf="stretch"
        size="lg"
        width="100%"
        colorScheme={widgetColorScheme as WidgetColorType}
      >
        {t('proceed')}
      </Button>
    </Step>
  )
}
