import Mixpanel from 'mixpanel-browser'
import { getIsSsr } from './helpers'
import logger from './logger'

export let distinctId: string | undefined

const { NEXT_PUBLIC_MIXPANEL_TOKEN } = process.env
Mixpanel.init(NEXT_PUBLIC_MIXPANEL_TOKEN, {
  autotrack: true,
  record_sessions_percent: 100,
  debug: process.env.NODE_ENV !== 'production',
  loaded: function (mixpanel) {
    distinctId = mixpanel.get_distinct_id()
  },
  ignore_dnt: true, // Do Not Track is ignored
})

const getIsMixpanelActive = () => {
  if (!NEXT_PUBLIC_MIXPANEL_TOKEN) {
    logger.warn('Mixpanel token is not set, the events will not be tracked')
    return false
  }
  // if (process.env.NODE_ENV !== 'production') {
  //   logger.warn('Mixpanel is disabled in development mode')
  //   return false
  // }
  if (getIsSsr()) {
    logger.warn('Mixpanel is disabled on the server side')
    return false
  }
  return true
}

export const getMixpanelUserId = () => {
  const cookie = document.cookie
    .split('; ') // Properly split cookies
    .find((c) => c.startsWith('mp_')) // Ensure prefix is correct
  
  if (!cookie) return undefined // Handle missing cookie

  try {
    const cookieValue: { $user_id?: string } = JSON.parse(decodeURIComponent(cookie.split('=')[1] || '{}'))
    return cookieValue.$user_id ?? undefined
  } catch (error) {
    logger.error('Failed to parse Mixpanel cookie:', error)
    return undefined
  }
}

export { Mixpanel as mixpanel, getIsMixpanelActive }
