import React from 'react'
import { chakra, Box, Flex, FlexProps, Heading, Icon } from '@chakra-ui/react'
import { AnimatePresence, PanInfo, useMotionValue, useTransform, animate } from 'framer-motion'
import { BsArrowLeft } from 'react-icons/bs'
import { MotionBox } from 'components/Motion'
import { NavigationDots } from './NavigationDots'
import { StepProps } from '.'
import { EmbedProps, WidgetColorType } from '../constants'
import { mixpanel } from 'utils/mixpanel'
import { TrackNamesEnum } from 'src/constants'

const variants = (offset: number) => ({
  hidden: (direction: number) => ({
    x: direction > 0 ? offset : -offset,
    opacity: 0,
    pointerEvents: 'none',
  }),
  show: {
    x: 0,
    opacity: 1,
    pointerEvents: 'unset',
  },
  exit: (direction: number) => ({
    x: direction < 0 ? offset : -offset,
    opacity: 0,
    pointerEvents: 'none',
  }),
})

const slideVariants = variants(12)
const titleVariants = variants(48)

const swipeConfidenceThreshold = 10000
const swipePower = (offset: number, velocity: number) => Math.abs(offset) * velocity

export const Stepper: React.FC<
  {
    canGoNext?(isSwipe?: boolean): boolean
    step: number
    setStep(step: number): void
  } & FlexProps
> = ({ canGoNext = () => true, step, setStep, children, ...props }) => {
  const x = useMotionValue(0)
  const [direction, setDirection] = React.useState(0)
  const titleTransform = useTransform(x, (initial) => initial * 0.075)
  const titleOpacity = useTransform(x, [-200, -120, 0, 120, 200], [0.15, 0.5, 1, 0.5, 0.15])
  const count = React.Children.count(children)
  const paginate = React.useCallback(
    (newDirection: number) => {
      let newStep = step + newDirection
      newStep = Math.max(0, Math.min(count - 1, newStep))
      setDirection(newDirection)
      setStep(newStep)
    },
    [count, step, setStep]
  )
  const goToStep = React.useCallback(
    (newStepArg: number) => {
      const newDirection = newStepArg > step ? 1 : -1
      const newStep = Math.max(0, Math.min(count - 1, newStepArg))
      setDirection(newDirection)
      setStep(newStep)
    },
    [step, count, setStep]
  )
  const nextStep = React.useCallback(
    (force?: boolean) => (force || canGoNext?.()) && paginate(1),
    [canGoNext, paginate]
  )
  const prevStep = React.useCallback(() => paginate(-1), [paginate])
  const child = React.useMemo(
    () => React.Children.toArray(children)[step],
    [step, children]
  ) as React.ReactElement<StepProps & Pick<EmbedProps, 'profile'>>
  if (step > count - 1) {
    return null
  }
  const { title, profile, isPreviousSwiperble } = child.props
  const { productSettings } = profile
  const hasHeader = step < count - 1 && (step > 0 || !!title)

  return (
    <>
      <Box overflow="hidden">
        <Flex py={child.props.py} pb={child.props.pb}>
          <Box width="full">
            <Box
              opacity={hasHeader ? 1 : 0}
              pointerEvents={hasHeader ? 'unset' : 'none'}
              transitionProperty="common"
              transitionDuration="normal"
            >
              <Flex position="relative" pt="10" px={props.px} minH="1.5rem" alignItems="center">
                <chakra.button
                  flex="none"
                  bg="gray.200"
                  borderRadius="md"
                  width="8"
                  height="8"
                  zIndex={1}
                  onClick={prevStep}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Icon as={BsArrowLeft} width="4" height="4" />
                </chakra.button>
                <Flex zIndex={0} flex="1" ml="-4">
                  <MotionBox
                    key={title}
                    flex="1"
                    custom={direction}
                    // variants={titleVariants}
                    initial={{
                      opacity: 0,
                      x: direction * 50,
                    }}
                    animate={{
                      opacity: 1,
                      x: 0,
                    }}
                    exit={{
                      opacity: 0,
                      x: direction * -50,
                    }}
                    style={{
                      x: titleTransform,
                      opacity: titleOpacity,
                    }}
                    // transition={{
                    //   x: {
                    //     type: 'spring',
                    //     damping: 30,
                    //     stiffness: 280,
                    //     restSpeed: 0.35,
                    //   },
                    // }}
                  >
                    <Heading
                      userSelect="none"
                      flex="1"
                      textAlign="center"
                      fontSize="lg"
                      color={productSettings?.widgetColorScheme as WidgetColorType}
                    >
                      {title}
                    </Heading>
                  </MotionBox>
                </Flex>
              </Flex>
            </Box>
            <AnimatePresence mode="wait" custom={direction}>
              <MotionBox
                key={step}
                width="full"
                flex="none"
                display="flex"
                flexDirection="column"
                userSelect="none"
                custom={direction}
                variants={slideVariants}
                initial="hidden"
                animate="show"
                exit="exit"
                // transition={{
                //   x: {
                //     type: 'spring',
                //     mass: 0.75,
                //     velocity: 9,
                //     damping: 20,
                //     stiffness: 180,
                //     restSpeed: 0.25,
                //   },
                // }}
                drag="x"
                onDrag={(_, info: PanInfo) => x.set(x.get() + info.delta.x)}
                dragConstraints={{ left: 0, right: 0 }}
                dragSnapToCenter
                dragElastic={0.13}
                onDragEnd={(_, { offset, velocity }: PanInfo) => {
                  const swipe = swipePower(offset.x, velocity.x)

                  if (swipe < -swipeConfidenceThreshold) {
                    if (canGoNext()) {
                      mixpanel.track(TrackNamesEnum.PaymentWidgetSwipe, {
                        paymentStep: step + 2,
                        profile,
                      })
                    }
                    nextStep()
                  } else if (
                    !(typeof isPreviousSwiperble === 'boolean' && !isPreviousSwiperble) &&
                    swipe > swipeConfidenceThreshold
                  ) {
                    mixpanel.track(TrackNamesEnum.PaymentWidgetSwipe, {
                      paymentStep: step,
                      profile,
                    })
                    prevStep()
                  }
                  void animate(x, 0, { duration: 0.1 })
                }}
                {...props}
                alignItems={child.props.alignItems || 'center'}
              >
                {React.cloneElement(child, {
                  nextStep,
                  goToStep,
                  custom: direction,
                })}
              </MotionBox>
            </AnimatePresence>
          </Box>
        </Flex>
      </Box>
      <NavigationDots
        position="absolute"
        length={count}
        activeIndex={step}
        activeDotColor={productSettings?.widgetColorScheme || undefined}
        x={x}
      />
    </>
  )
}
