import pino from 'pino'

const logger = pino({
  level: process.env.NODE_ENV === 'production' ? 'info' : 'debug',
  serializers: {
    error: pino.stdSerializers.err,
  },
  transport:{
          target: 'pino-pretty',
          options: { colorize: true, translateTime: 'HH:MM:ss Z' },
        }
    // process.env.NODE_ENV === 'production'
    //   ? {
    //       target: 'pino-pretty',
    //       options: { colorize: true, translateTime: 'HH:MM:ss Z' },
    //     }
    //   : {
    //       target: './logger-prisma-transport',
    //       options: {
    //         destination: 1,
    //       },
    //     },
})

export default logger
