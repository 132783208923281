import { Button, Flex, Heading, Icon, Text, VStack, chakra } from '@chakra-ui/react'
import { CoverPicture, ProfilePicture } from 'components/Merchant'
import useTranslation from 'next-translate/useTranslation'
import { lighten } from 'polished'
import React from 'react'
import { BiLock } from 'react-icons/bi'
import { TrackNamesEnum } from 'src/constants'
import colors from 'theme/foundations/colors'
import { usePlatformConfig } from 'utils/hooks'
import { mixpanel } from 'utils/mixpanel'
import { Step, StepProps } from '.'
import { EmbedProps, WidgetColorType } from '../constants'

const coverPictureUrl = 'https://picsum.photos/1440/678'
const pictureUrl = 'https://picsum.photos/240/240?1'

export const Intro: React.FC<StepProps & Pick<EmbedProps, 'profile'>> = ({
  nextStep,
  profile,
  ...props
}) => {
  const { t } = useTranslation('common')
  const config = usePlatformConfig()
  const { productSettings, profilePicture, coverPicture } = profile
  const { widgetColorScheme, buttonText, headingText, mainText, isPersonalised } =
    productSettings || {}
  const [colorKey, gradeKey] = (widgetColorScheme?.split('.') ?? ['primary', 500]) as [
    colorKey: keyof typeof colors,
    gradeKey: number,
  ]
  const colorSchemeHex = colors[colorKey][gradeKey] as string
  return (
    <Step {...props} alignItems="center" width="100%">
      {isPersonalised ? (
        <chakra.div
          position="relative"
          w="full"
          alignSelf="stretch"
          bg="white"
          overflow="hidden"
          borderRadius={{ base: '4xl', md: '5xl' }}
          userSelect="none"
          mt="-70px"
        >
          <CoverPicture
            height={{ base: '120px', md: '150px' }}
            pictureUrl={coverPicture?.thumbnailSignedUrl || coverPictureUrl}
            borderRadius="inherit"
            borderBottomLeftRadius={0}
            borderBottomRightRadius={0}
          />
          <chakra.div p="6">
            <chakra.div
              mt={{ base: '-4.25rem', md: '-20' }}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <ProfilePicture
                pictureUrl={profilePicture?.thumbnailSignedUrl || pictureUrl}
                size="sm"
              />
            </chakra.div>
          </chakra.div>
        </chakra.div>
      ) : (
        <config.components.LogoIcon mt="-10" width="53px" height="55px" />
      )}
      <VStack spacing="4">
        <Heading
          textAlign="center"
          fontSize="xl"
          lineHeight="short"
          color={widgetColorScheme || undefined}
        >
          {headingText || (
            <>
              {t('easyWay')}
              <br />“{t('thankYou')}”
            </>
          )}
        </Heading>
        <Text as="div" textAlign="center" color={widgetColorScheme || undefined}>
          {mainText || (
            <Text>
              {t('supportFavorite')}
              <br />
              {t('orProject')}
            </Text>
          )}
        </Text>
      </VStack>
      <Flex
        alignItems="center"
        color={widgetColorScheme ? (lighten(0.5, colorSchemeHex) as WidgetColorType) : 'teal.200'}
      >
        <Icon as={BiLock} width="5" height="5" />
        <Text ml="2" fontSize="sm">
          {t('secureDonation')}
        </Text>
      </Flex>
      <Button
        alignSelf="stretch"
        size="lg"
        width="100%"
        colorScheme={widgetColorScheme as WidgetColorType}
        onClick={() => {
          nextStep?.()
          mixpanel.track(TrackNamesEnum.PaymentButton, {
            paymentStep: 1,
            profile,
          })
        }}
      >
        {buttonText || t('supportNow')}
      </Button>
    </Step>
  )
}
